<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/home/contact_bg.jpg" alt="">
    </div>
    <div v-for="(item,index) in info" :key="index">
      <div v-if="item.flag === '14'" class="contact_info">
        <div class="home_content">
          <div class="info">
            <div class="head">
              <div class="left">
                <p class="home_title">{{ item.shortTitle }}</p>
                <p class="home_sub">{{ item.title }}</p>
              </div>
            </div>
            <div class="text">
              <p v-for="(cItem,cIndex) in item.children" :key="cIndex">{{ cItem.title }}：{{ cItem.remark }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.flag === '16'" class="text_list">
        <div class="home_content">
          <div class="head">
            <div class="left">
              <p class="home_title">{{ item.shortTitle }}</p>
              <p class="home_sub">{{ item.title }}</p>
            </div>
          </div>
          <ul v-if="noticeList.length" class="list">
            <li v-for="nItem in noticeList" :key="nItem.id" @click="detailArchieve(nItem,154)">
              <p class="text">{{ nItem.title }}</p>
              <p class="date">{{ nItem.auditTime }}</p>
            </li>
          </ul>
          <div v-else class="no_info">暂无数据</div>
          <div v-if="noticeList.length" class="pagination_con">
            <el-pagination background layout="prev, pager, next" :current-page="notice.currentPage" :total="notice.total" @current-change="noticeCurrentChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMainContent, getArchiveListByMenuId } from '@/plugin/api'
export default {
  data() {
    return {
      info: [],
      noticeList: [],
      notice: {
        total: 0,
        currentPage: 1
      }
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) this.changeTop()
      }
    }
  },
  async mounted() {
    await this.getInfo()
    this.changeTop()
  },
  methods: {
    noticeCurrentChange(number) {
      this.notice.currentPage = number
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 144, pageNum: this.anno.currentPage, pageSize: 10 }).then(res => {
        this.noticeList = res.data.data.archiveList
      })
    },
    changeTop() {
      const url = this.$route.query.url
      let sTop = 0
      switch (url) {
        case '108':
          sTop = 0
          break
        case '145':
          sTop = 0
          break
        case '144':
          sTop = 10300
          break
      }
      setTimeout(() => {
        window.scrollTo(0, sTop - 60)
      }, 100)
    },
    detailArchieve(item, num) {
      const routeData = this.$router.resolve({
        path: '/archieveDetail', query: { menuId: num, id: item.id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/archieveDetail', query: { menuId: num, id: item.id }})
    },
    async getInfo() {
      const { query } = this.$route
      const urlType = query.fUrlType ? query.fUrlType : query.urlType
      const url = query.fUrl ? query.fUrl : query.url
      await getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        this.info = res.data.data.contentMainList[0].contentItemTreeList
      })
      await getArchiveListByMenuId({ tenantId: 'xljr', menuId: 154, pageNum: this.notice.currentPage, pageSize: 10 }).then(res => {
        this.noticeList = res.data.data.archiveList
        this.notice.total = res.data.data.total
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination_con{
  display: flex;
  margin: 20px 0;
  .el-pagination{
    margin: 0 auto;
  }
}
.contact_info{
  background-image: url(../../statics/images/home/contact.jpg);
  background-size: cover;
  background-position-x: -55px;
  .home_content{
    padding: 100px 0;
    display: flex;
    height: 450px;
    justify-content: space-between;
    .info{
      .head{
        margin-bottom: 100px;
      }
      .text{
        p{
          margin-bottom: 20px;
        }
      }
    }
    .img{
      width: 650px;
      background-color: #000;
    }
  }
}
.text_list{
  padding: 50px 0;
  .home_content{
    .list{
      margin-top: 80px;
      li{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        color: #666666;
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
